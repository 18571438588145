import React, { useEffect, useState } from 'react';
import NavbarShop from './NavbarShop';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../Footer';

export default function Cart() {
  const products = useSelector((state) => state.CartReducer);
  const user = useSelector((state) => state.UserReducer);
  const [isBook, setIsBook] = useState(false);
  let [totlaPrice, setTotalPrice] = useState(0); // Set total price as state
  const Dispatch = useDispatch();

  const remove = (id_product, quantity, type_product) => {
    Dispatch({
      type: 'remove',
      payload: {
        id_product: id_product,
        type_product: type_product,
      },
    });
    Dispatch({
      type: 'incraseByContity',
      payload: { numbre: quantity },
    });

    // Update the total price
    const item = products.panier.find(
      (p) => p.id_product === id_product && p.type_product === type_product
    );
    if (item) {
      setTotalPrice((prevPrice) => prevPrice - item.price * item.quantity);
    }
  };

  const incraseAndDecrase = (choix, id_product, type_product, price) => {
    const item = products.panier.find(
      (p) => p.id_product === id_product && p.type_product === type_product
    );

    if (!item) return;

    if (choix === 'inc') {
      if (item.quantity < 10) {
        Dispatch({ type: 'incrase' });
        Dispatch({ type: 'incraseQuantity', payload: { id_product, type_product } });

        // Increment total price
        setTotalPrice((prevPrice) => prevPrice + price);
      }
    } else if (choix === 'dec') {
      if (item.quantity > 1) {
        Dispatch({ type: 'decrase' });
        Dispatch({ type: 'decraseQuantity', payload: { id_product, type_product } });

        // Decrement total price
        setTotalPrice((prevPrice) => prevPrice - price);
      }
    }
  };

  const checkout = (price) => {
    if (!user.user.connected) {
      alert('You must be logged in to access this page Please log in to continue.');
      window.location = '/';
      return;
    } else {
      localStorage.setItem('totalPrice', price);
      window.location = '/checkout';
    }
  };

  useEffect(() => {
    // Recalculate total price whenever products are updated
    const total = products.panier.reduce((sum, item) => sum + item.price * item.quantity, 0);
    setTotalPrice(total);

    // Check if any book exists in the cart
    const hasBook = products.panier.some((item) => item.type_product === 'book');
    setIsBook(hasBook);
  }, [products.panier]);

  
 const total=isBook?totlaPrice + 40 : totlaPrice
  return (
    <>
      <NavbarShop />

      <div style={{ padding: '20px', minHeight: 'calc(100vh - 120px)' }}>
        <div
          style={{
            margin: '0 auto',
            maxWidth: '900px',
            textAlign: 'center',
            color: '#333',
          }}
        >
          <h1 style={{ marginTop: '50px', fontWeight: 'bold', fontSize: '30px' }}>Shipping Cart</h1>
        </div>

        <div style={{ marginTop: '30px', marginBottom: '20px' }}>
          {/* Cart Table */}
          <div style={{ padding: '15px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0px 4px 6px rgba(0,0,0,0.1)' }}>
            <div style={{ overflowX: 'auto' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'left' }}>
                <thead style={{ backgroundColor: '#f8f9fa' }}>
                  <tr>
                    <th style={{ padding: '8px 10px', textTransform: 'uppercase', fontSize: '12px', borderBottom: '2px solid #ddd' }}>Produit</th>
                    <th style={{ padding: '8px 10px', textTransform: 'uppercase', fontSize: '12px', borderBottom: '2px solid #ddd' }}>Titre</th>
                    <th style={{ padding: '8px 10px', textTransform: 'uppercase', fontSize: '12px', borderBottom: '2px solid #ddd' }}>Prix</th>
                    <th style={{ padding: '8px 10px', textTransform: 'uppercase', fontSize: '12px', borderBottom: '2px solid #ddd' }}>Quantity</th>
                    <th style={{ padding: '8px 10px', textTransform: 'uppercase', fontSize: '12px', borderBottom: '2px solid #ddd' }}>Supprimer</th>
                  </tr>
                </thead>
                <tbody>
                  {products.panier.map((item, i) => (
                    <tr key={i}>
                      <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                        <img
                          src={'https://edu-hasnaoui.com/image/' + item.image}
                          alt=""
                          style={{
                            width: '50px',
                            height: 'auto',
                            borderRadius: '5px',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                          }}
                        />
                      </td>
                      <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                        <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{item.title}</span>
                      </td>
                      <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                        <span style={{ fontWeight: 'bold', fontSize: '12px' }}>{item.price} DH</span>
                      </td>
                      <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <button
                            onClick={() => incraseAndDecrase('dec', item.id_product, item.type_product, item.price)}
                            style={{
                              background: '#333',
                              color: '#fff',
                              border: 'none',
                              padding: '5px',
                              marginRight: '5px',
                              cursor: 'pointer',
                              borderRadius: '4px',
                              fontSize: '12px',
                            }}
                          >
                            -
                          </button>
                          <input
                            type="text"
                            value={item.quantity}
                            readOnly
                            style={{
                              width: '30px',
                              textAlign: 'center',
                              border: '1px solid #ddd',
                              borderRadius: '4px',
                              padding: '2px',
                              fontSize: '12px',
                            }}
                          />
                          <button
                            onClick={() => incraseAndDecrase('inc', item.id_product, item.type_product, item.price)}
                            style={{
                              background: '#333',
                              color: '#fff',
                              border: 'none',
                              padding: '5px',
                              marginLeft: '5px',
                              cursor: 'pointer',
                              borderRadius: '4px',
                              fontSize: '12px',
                            }}
                          >
                            +
                          </button>
                        </div>
                      </td>
                      <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                        <a
                          href="#"
                          onClick={() => remove(item.id_product, item.quantity, item.type_product)}
                          style={{ color: 'red', fontSize: '14px' }}
                        >
                          🗑
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Order Summary */}
        <div
          style={{
            width: '100%',
            maxWidth: '350px',
            background: '#f8f9fa',
            padding: '20px',
            borderRadius: '12px',
            boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
            textAlign: 'left',
            marginTop: '30px',
            marginLeft: 'auto',
            marginRight: 'auto',
            fontSize: '12px',
          }}
        >
          <h3 style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'center', marginBottom: '15px' }}>
            RÉSUMÉ DE LA COMMANDE
          </h3>
          <ul style={{ listStyleType: 'none', padding: '0', marginBottom: '20px' }}>
            <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <span>Sous-total</span> <strong>{totlaPrice}DH</strong>
            </li>
            <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <span>Livraison</span> <strong>{isBook ? '40DH' : '0DH'}</strong>
            </li>
            <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <span>Total</span> <strong>{total}DH</strong>
            </li>
          </ul>
          <button
            onClick={() => checkout(total)}
            style={{
              background: '#333',
              color: '#fff',
              width: '100%',
              padding: '10px',
              borderRadius: '8px',
              fontSize: '14px',
              fontWeight: 'bold',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            Passer à la caisse
          </button>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
}
